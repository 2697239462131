import useAppContext from 'app/store/useAppContext'
import { Slider } from 'shared/ui/slider'

import { cardsContent } from '../_model/cards-content'
import Card from '../_ui/Card'

import styles from './Cards.module.scss'

export default function Cards() {
  const {
    store: { isMobile },
  } = useAppContext()

  const cards = cardsContent.map((card) => {
    return (
      <Card
        key={card?.id}
        id={card?.id}
        title={card?.title}
        desc1={card?.desc1}
        desc2={card?.desc2}
      />
    )
  })

  return (
    <>
      {isMobile ? (
        <Slider className={styles.cards_slider}>{cards}</Slider>
      ) : (
        <ul className={styles.cards}>{cards}</ul>
      )}
    </>
  )
}
