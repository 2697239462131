import React, { Dispatch, useEffect } from 'react'

import type { IAction } from 'app/store/model/action-types'
import type { IUser } from 'entities/user/model/user'
import {
  setIsAffirmationModal,
  setIsPrivacyPolicyModal,
  setIsSuccessAffirmationModal,
  setUserAccepted,
  setUserInfo,
} from 'app/store/actions'
import type { IFormInputs } from 'features/affirmation/_model/IFormInputs'

import { useForm } from 'shared/lib/react-hook-form'
import { yupResolver } from 'shared/lib/yup-resolver'
import classNames from 'shared/lib/class-names'
import { Button } from 'shared/ui/button'

import schema from '../_schema/schema'
import AcceptModal from '../_ui/accept-modal/accept-modal'

import cls from './Form.module.scss'

interface IAffirmationFormProps {
  user: IUser
  dispatch: Dispatch<IAction>
  title?: string
  description?: string
  className?: string
  isAffirmationModal?: boolean
}

export function AffirmationForm(props: IAffirmationFormProps) {
  const { user, dispatch, title, description, isAffirmationModal, className } =
    props

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IFormInputs>({
    defaultValues: {
      isPolitics: true,
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  })

  const openPrivacyPolicyModal = () => {
    dispatch(setIsPrivacyPolicyModal(true))
  }
  const openAcceptModal = () => {
    dispatch(setIsSuccessAffirmationModal(true))
  }
  const closeAffirmationModal = () => {
    dispatch(setIsAffirmationModal(false))
  }
  const onChecked = (e: any, id: any) => {
    const value = e?.target?.checked

    setValue(id, value)

    dispatch(setUserAccepted({ [id]: value }))
  }

  const onSubmit = handleSubmit(async (data: any) => {
    setValue('isPolitics', user.accepted.isPolitics)

    dispatch(
      setUserInfo({
        ...user?.info,
        firstName: data?.firstName,
        email: data?.email,
      }),
    )

    closeAffirmationModal()
    openAcceptModal()
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      dispatch(
        setUserAccepted({
          isPolitics: true,
        }),
      )

      reset()
    }
  }, [isSubmitSuccessful, reset])

  const modalId = isAffirmationModal ? '-modal' : ''

  return (
    <form
      // @ts-ignore
      onSubmit={handleSubmit(onSubmit)}
      className={classNames(cls.form, className)}
    >
      <h3 className={cls.form__title}>{title}</h3>

      {description && <p className={cls.form__desc}>{description}</p>}

      <div className={cls.form__names}>
        <div className={cls.form__input_block}>
          <input
            {...register('firstName')}
            id={'firstName' + modalId}
            name={'firstName'}
            placeholder="Имя"
            type="text"
          />
          {errors?.firstName && (
            <p className={cls.errors}>{errors.firstName.message}</p>
          )}
        </div>
      </div>

      <div className={cls.form__input_block}>
        <input
          {...register('email')}
          id={'email' + modalId}
          name={'email'}
          placeholder="Email"
          type="email"
        />
        {errors?.email && <p className={cls.errors}>{errors.email.message}</p>}
      </div>

      <label className={cls.checkSmallLabel} htmlFor="politics">
        <input
          {...register('isPolitics')}
          checked={isAffirmationModal && user.accepted.isPolitics}
          onChange={(e) => onChecked(e, 'isPolitics')}
          className={cls.checkSmall}
          type="checkbox"
        />
        <span className={cls.checkSmallLabelText}>
          <>
            Даю согласие на обработку персональных данных в соответствии с{' '}
            <span onClick={openPrivacyPolicyModal}>
              Политикой конфиденциальности
            </span>
          </>
        </span>
      </label>

      <Button className={cls.button} type="submit">
        Отправить заявку
      </Button>

      <AcceptModal />
    </form>
  )
}
