import { Dispatch } from 'react'

import type { IAction } from 'app/store/model/action-types'

import { setDocumentModalType } from 'app/store/actions'
import { Modal } from 'shared/ui/modal'
import { documents, DocumentsType } from '../_model/documents'

import styles from './DocumentItem.module.scss'

interface IDocumentItemProps {
  name: string
  icon: string
  isMobileSmall: boolean
  documentModalType: any
  docType: keyof DocumentsType
  dispatch: Dispatch<IAction>
}

const customModalStyles = {
  content: {
    maxHeight: '100vh',
    overflow: 'auto',
  },
}
const customMobileModalStyles = {
  content: {
    width: '100vw',
    height: `calc(100vh - 87px)`,
    margin: '87px 0 0 0',
    overflow: 'auto',
  },
}

export default function DocumentItem(props: IDocumentItemProps) {
  const { name, icon, docType, isMobileSmall, documentModalType, dispatch } =
    props

  const openModal = () => {
    dispatch(setDocumentModalType(docType))
  }
  const closeModal = () => {
    dispatch(setDocumentModalType(null))
  }

  return (
    <>
      <div className={styles.item} onClick={openModal}>
        <img src={icon} alt="doc" />
        <p>{name}</p>
      </div>

      <Modal
        style={isMobileSmall ? customMobileModalStyles : customModalStyles}
        isOpen={documentModalType === docType}
        onRequestClose={closeModal}
        isCloseIcon={!isMobileSmall}
        isTitle
        content={
          <div className={styles.modal__item}>
            <img src={documents[docType] as string} alt={docType} />
          </div>
        }
      />
    </>
  )
}
