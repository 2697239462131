import classNames from 'shared/lib/class-names'

import cls from './Cards.module.scss'

interface Card {
  id: number
  title: string
  desc1: string
  desc2?: string
}

export default function Card(props: Card) {
  const { id, title, desc1, desc2 } = props

  return (
    <li key={id} className={classNames(cls.card)}>
      <h3 className={cls.card__title}>{title}</h3>
      <div className={cls.card__desc}>
        <p className={cls.desc1}>{desc1}</p>
        {desc2 && <p className={cls.desc2}>{desc2}</p>}
      </div>
    </li>
  )
}
