import { ReactNode } from 'react'

import MfcaImg from 'shared/assets/images/documents/afca.png'
import TradeLicenseImg from 'shared/assets/images/documents/global-plc.png'
import IssueLicenseImg from 'shared/assets/images/documents/broker-license.png'
import BankLicenseImg from 'shared/assets/images/documents/bank-license.png'

export type DocumentsType = {
  mfca: ReactNode
  trade_license: ReactNode
  issue_license: ReactNode
  bank_license: ReactNode
}

export const documents: DocumentsType = {
  mfca: MfcaImg,
  trade_license: TradeLicenseImg,
  issue_license: IssueLicenseImg,
  bank_license: BankLicenseImg,
}
