import type { IAppStore } from './app-store'

export const initialState: IAppStore = {
  isMobile: false,
  isMobileSmall: false,
  modals: {
    isCookiesModal: false,
    isAcceptCookiesModal: false,
    isPrivacyPolicyModal: false,
    isAffirmationModal: false,
    isSuccessAffirmationModal: false,
    documentModalType: null,
  },
  user: {
    api: {
      ip: '',
      hostname: '',
      city: '',
      region: '',
      country: '',
      loc: '',
      org: '',
      postal: '',
      timezone: '',
    },
    searchParams: {
      external_id: '',
      metric: '',
      gaid: '',
      sub1: '',
      sub2: '',
      sub3: '',
      sub4: '',
      sub5: '',
      sub6: '',
      sub7: '',
      apps_id: '',
      devKey: '',
      bundle: '',
      status: '',
    },
    accepted: {
      isPolitics: true,
      isAge: true,
      whatsapp: true,
      telegram: false,
      viber: false,
    },
    info: {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      callHours: '',
    },
  },
}
