import React, { ButtonHTMLAttributes } from 'react'

import classNames from 'shared/lib/class-names'

import cls from './Button.module.scss'

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string
}

export function Button(props: IButton) {
  const { className } = props

  return <button {...props} className={classNames(cls.button, className)} />
}
