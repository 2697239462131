import { useState } from 'react'
import UpIcon from 'shared/assets/icons/chevron-up.webp'

import { questionsContent } from '../_model/questions-content'

import cls from './QuestionsAccordion.module.scss'
import classNames from 'classnames'

interface IAccordionProps {
  className?: string
}

export default function QuestionsAccordion({ className }: IAccordionProps) {
  const [openStates, setOpenStates] = useState<boolean[]>(
    questionsContent.map(() => false),
  )

  const toggleAccordion = (index: number) => {
    setOpenStates((prevStates) => {
      const newStates = [...prevStates]
      newStates[index] = !newStates[index]
      return newStates
    })
  }

  return (
    <div className={`${cls.accordion} ${className ?? ''}`}>
      {questionsContent.map((item, index) => (
        <div
          key={index}
          className={cls['accordion__item']}
          onClick={() => toggleAccordion(index)}
        >
          <div className={cls.header}>
            <p className={cls['header__title']}>{item.title}</p>
            <button
              className={classNames(
                cls['header__btn'],
                openStates[index] ? cls['header__btn--down'] : null,
              )}
            >
              <img src={UpIcon} alt="UpIcon" />
            </button>
          </div>
          <div
            className={
              openStates[index]
                ? cls['accordion__content--open']
                : cls['accordion__content--closed']
            }
          >
            <div className={cls.item}>
              <p>{item?.desc1}</p>
              <p>{item?.desc2}</p>
              <p>{item?.desc3}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
