export const isScreenWidth = (width: number) => {
  return window.innerWidth < width
}

export function getRandomObjects(array: any[], count: number) {
  const shuffled = array.sort(() => 0.5 - Math.random())
  return shuffled.slice(0, count)
}

export function someObjValuesTrue(obj: any) {
  for (let key in obj) {
    if (obj[key]) {
      return true
    }
  }
  return false
}

export function setAllObjValuesFalse(obj: any) {
  for (let key in obj) {
    obj[key] = false
  }

  return obj
}
