type CookieDescriptionType = {
  isMobile?: boolean
  className?: string
}

export default function CookieDescription({
  isMobile,
  className,
}: CookieDescriptionType) {
  return (
    <>
      <p className={className}>
        Уважаемый пользователь!{`\n\n`}
        {isMobile ? (
          <>
            Продолжая использование нашего веб-сайта, Вы выражаете свое согласие
            на использование куки-файлов в соответствии с нашей политикой
            конфиденциальности. Если Вы не согласны с использованием
            куки-файлов, пожалуйста, покиньте наш сайт.
          </>
        ) : (
          <>
            Данный веб-сайт использует технологию "куки" для улучшения качества
            предоставляемых услуг и повышения удобства использования веб-сайта.
            При этом мы соблюдаем принципы обработки персональных данных и
            гарантируем их конфиденциальность. Куки-файлы не содержат
            персональной информации и не могут использоваться для идентификации
            пользователя. Подробнее о том, как мы обрабатываем персональные
            данные, Вы можете ознакомиться в нашей политике конфиденциальности.
            Продолжая использование нашего веб-сайта, Вы выражаете свое согласие
            на использование куки-файлов в соответствии с нашей политикой
            конфиденциальности. Если Вы не согласны с использованием
            куки-файлов, пожалуйста, покиньте наш сайт.
          </>
        )}
      </p>
    </>
  )
}
