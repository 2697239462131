import Cookies from 'js-cookie'

interface IGetOrRemoveCookies {
  name: string
}
interface ISetCookies extends IGetOrRemoveCookies {
  token: string
  expires?: number | Date | undefined
  sameSite?: 'strict' | 'Strict' | 'lax' | 'Lax' | 'none' | 'None' | undefined
}

export class CookiesService {
  static set<T>({ name, token, expires, sameSite }: ISetCookies): T | null {
    try {
      Cookies.set(name, token, { expires: expires || 7, sameSite: sameSite || 'strict' })
    } catch (e) {
      console.error(e)
    }
    return null
  }
  static get<T>({ name }: IGetOrRemoveCookies): T | null {
    try {
      const value = Cookies.get(name) || null
      if (!value) {
        return null
      }
      return value as T
    } catch (e) {
      console.error(e)
    }
    return null
  }
  static remove({ name }: IGetOrRemoveCookies) {
    Cookies.remove(name)
  }
}
