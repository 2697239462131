import useAppContext from 'app/store/useAppContext'
import classNames from 'shared/lib/class-names'

import { cardsContent } from './_model/cards-content'
import Card from './_ui/Card'
import Cards from './_ui/Cards'

import cls from './HovInvest.module.scss'

export default function HovInvest() {
  const {
    store: { isMobile },
  } = useAppContext()

  return (
    <section
      className={classNames('flex__col_center', cls.page)}
      id="hovInvest"
    >
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          <h3 className={cls.title}>
            Как инвестировать
            <br />в <span>FREEDOM FINANCE</span>
          </h3>
        </div>
        <div className={cls.right_block}>
          {!isMobile && (
            <div className={cls.cards__desktop}>
              {cardsContent.map((card) => {
                return <Card key={card?.id} {...card} />
              })}
            </div>
          )}
          {isMobile && <Cards />}
        </div>
      </div>
    </section>
  )
}
