import { useEffect } from 'react'

import useAppContext from 'app/store/useAppContext'
import { setIsAcceptCookiesModal } from 'app/store/actions'
import { CookiesService } from 'shared/services/Cookies.service'

export default function useIsAcceptedCookies() {
  const { dispatch } = useAppContext()

  const isAcceptedCookies =
    CookiesService.get({ name: 'accept_cookies' }) === 'accepted'

  const openModal = () => {
    dispatch(setIsAcceptCookiesModal(true))
  }

  useEffect(() => {
    if (!isAcceptedCookies) {
      openModal()
    }
  }, [isAcceptedCookies])
}
