import classNames from 'shared/lib/class-names'

import cls from './Cards.module.scss'

interface Card {
  id: number
  icon: string
  title: string
  desc1: string
  desc2?: string
}

export default function Card(props: Card) {
  const { id, icon, title, desc1, desc2} = props

  return (
    <li key={id} className={classNames(cls.card)}>
      <div className={cls.card__img}>
        <img src={icon} alt={`card-${id}`} />
      </div>
      <h3 className={cls.card__title}>{title}</h3>
      <div className={cls.card__desc}>
        <p>{desc1}</p>
        {desc2 && <p>{desc2}</p>}
      </div>
    </li>
  )
}
