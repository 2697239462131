import LogoIcon from 'shared/assets/icons/logo-full.webp'
import styles from './Logo.module.scss'

export function Logo() {
  return (
    <a href="/" className={styles.logo}>
      <img src={LogoIcon} alt="logo" />
    </a>
  )
}
