import { Outlet } from 'react-router-dom'

import Header from 'widgets/header'
import Footer from 'widgets/footer'
import useSetIsMobileModal from 'features/modal/use-cases/useSetIsMobileModal'
import AcceptCookieModal from 'features/cookie/AcceptCookieModal'
import useIsMobileModal from 'features/modal/use-cases/useIsMobileModal'
import classNames from 'shared/lib/class-names'

export const MainLayout = () => {
  const { isMobileModal, isSomeModalsOpened } = useIsMobileModal()

  useSetIsMobileModal()

  return (
    <div className="app">
      <Header />
      <main
        className={classNames(
          'main',
          isMobileModal && isSomeModalsOpened ? 'main--fixed' : null,
        )}
      >
        <Outlet />
      </main>
      <Footer />

      <AcceptCookieModal />
    </div>
  )
}
