import useAppContext from 'app/store/useAppContext'
import { AffirmationForm } from 'features/affirmation/form/form'
import classNames from 'shared/lib/class-names'
import CompaniesImg from 'shared/assets/images/open-account/companies.webp'

import cls from 'pages/home/contacts/Contacts.module.scss'

export default function Contacts() {
  const {
    store: { isMobile, user },
    dispatch,
  } = useAppContext()

  const modalTitle = isMobile
    ? 'Откройте торговый счет'
    : 'Откройте торговый счет'

  const modalDescription =
    'Накопительные планы в евро и долларах с повышенными ставками. Акции, ETF, облигации и опционы на акции. Прямой доступ к крупнейшим биржам США, Европы и Азии. Отмеченная наградами аналитика по ценным бумагам и образовательные курсы.'

  return (
    <section className={classNames('flex__row_center', cls.page)} id="contacts">
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          <AffirmationForm
            user={user}
            dispatch={dispatch}
            title={modalTitle}
            description={modalDescription}
          />
        </div>

        {!isMobile && (
          <div className={cls.right_block}>
            <img src={CompaniesImg} alt="CompaniesImg" />
          </div>
        )}
      </div>
    </section>
  )
}
