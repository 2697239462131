import React from 'react'
import ReactDOM from 'react-dom/client'

import { AppContextProvider } from 'app/store/AppContextProvider'
import App from './app/App'
import reportWebVitals from './reportWebVitals'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './shared/fonts/font.scss'
import './app/styles/index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </React.StrictMode>,
)

reportWebVitals()
