import useAppContext from 'app/store/useAppContext'
import classNames from 'shared/lib/class-names'
import ManImg from 'shared/assets/images/money-protect/man.webp'

import cls from './ProtectMoney.module.scss'

export default function ProtectMoney() {
  const {
    store: { isMobile },
  } = useAppContext()

  const title = (
    <h3 className={cls.card__title}>
      Ваши деньги
      <br />
      под <span>нашей защитой</span>
    </h3>
  )

  return (
    <section
      className={classNames('flex__col_center', cls.page)}
      id="protect_money"
    >
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          {isMobile && (
            <>
              {title}
              <p className={cls.card__desc_mobile}>
                Инвестируйте свои деньги только в надежные компании и проекты
              </p>
            </>
          )}
          <div className={cls.left_block__content}>
            <img src={ManImg} alt="ManImg" />
          </div>
        </div>
        <div className={cls.right_block}>
          <div className={cls.card}>
            {!isMobile && title}
            <p className={cls.card__desc}>
              <span>
                <span>Freedom Finance</span> - дочерняя компания Freedom Holding
                Corp с капитализацией $3,8 млрд.
              </span>
              <span>
                Мы являемся <span>единственным</span> брокером, представленным в
                Европе и Азии, с листингом на Nasdaq.
              </span>
              <span>
                Обладаем <span>лицензией CySEC</span> и долгосрочным кредитным
                рейтингом "BB" от <span>Standard & Poor's Global Ratings</span>.
              </span>
              <span>
                Подотчетны регуляторам — CySEC и SEC. Прозрачны, надежны и
                открыты для каждого.
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
