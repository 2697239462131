import DocumentIcon from 'shared/assets/icons/document-icon.webp'

export type DocumentsContentType = {
  title: string
  name: string
  docType: string
  icon: string
}

export const documentsContent: DocumentsContentType[] = [
  {
    title: 'Свидетельство о регистрации МФЦА',
    name: 'Freedom Finance MFCA.pdf',
    icon: DocumentIcon,
    docType: 'mfca',
  },
  {
    title: 'Лицензия на торговлю бумагами',
    name: 'Freedom Finance License.pdf',
    icon: DocumentIcon,
    docType: 'trade_license',
  },
  {
    title: 'Лицензия на деятельность брокера',
    name: 'Freedom Finance Broker License.pdf',
    icon: DocumentIcon,
    docType: 'issue_license',
  },
  {
    title: 'Лицензия для банковских операций',
    name: 'Freedom Finance Bank License.pdf',
    icon: DocumentIcon,
    docType: 'bank_license',
  },
]
