import React, { PropsWithChildren } from 'react'
import { default as SliderLib, Settings } from 'react-slick'

import classNames from 'shared/lib/class-names'

import './Slider.scss'

export function Slider(props: PropsWithChildren<Settings>) {
  const { children } = props

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <div className={classNames('slider-container', 'slider-custom')}>
      <SliderLib {...props} {...settings}>
        {children}
      </SliderLib>
    </div>
  )
}
