import useAppContext from 'app/store/useAppContext'
import DocumentsAccordion from 'features/documents/accordion'
import classNames from 'shared/lib/class-names'
import ManLicenseImg from 'shared/assets/images/documents/man-license.webp'

import cls from './Documents.module.scss'

export default function Documents() {
  const {
    store: { isMobile },
  } = useAppContext()

  return (
    <section
      className={classNames('container__desktop', 'flex__col_center', cls.page)}
    >
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          <h3 className={cls.header__title}>
            Наши <span>лицензии</span> и <span>документация</span>
          </h3>
          {isMobile && (
            <div className={cls.right_block__content}>
              <img src={ManLicenseImg} alt="ManLicenseImg" />
            </div>
          )}
          <div className={cls.content}>
            <DocumentsAccordion className={cls.accordion} />
          </div>
        </div>
        <div className={cls.right_block}>
          {!isMobile && (
            <div className={cls.right_block__content}>
              <img src={ManLicenseImg} alt="ManLicenseImg" />
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
